.subjects {
  box-sizing: border-box;
  margin: 20px 0;
  &__wrapper {
    @include display-column;
  }
  &__block {
    width: 100%;
    max-width: 864px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  &__title {
    color: #fff;
    font-size: 26px;
    font-family: $ffSFRegular;
    margin-top: 62px;
    margin-bottom: 20px;
  }
  &__classNumber {
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
    font-family: $ffSFMedium;
  }
  &__lists {
    display: inline-grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    grid-row-gap: 20px;
    grid-column-gap: 1rem;
  }
  &__list {
    margin-top: 12px;
    font-size: 20px;
    font-family: $ffSFMedium;
    pointer-events: none !important;
    color: rgba(255, 255, 255, 0.3);
    &:first-of-type {
      color: #18acff;
      pointer-events: all !important;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
  .colorBlack {
    color: rgba(255, 255, 255, 0.3);
    text-decoration: none;
  }
  .dislpayColumn {
    @include display-column;
  }
  .maxWidth100per {
    max-width: 100%;
  }
  .margTop30px {
    margin-top: 40px;
  }
}

@media only screen and (max-width: $lg) {
  .subjects {
    display: none;
  }
}
