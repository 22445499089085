.news {
  @include display-row-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1324px;
  margin-top: 153px;
  padding: 0 20px;
  box-sizing: border-box;
  .news__img {
    width: 100%;
    height: 100%;
    max-width: 752px;
    max-height: 409px;
  }
  .news__info {
    width: 100%;
    box-sizing: border-box;
    margin-left: 54px;
    margin-top: 45px;
    .news__title {
      color: #b0ff4d;
      font-size: 28px;
      font-family: $ffSFMedium;
    }
    .news__date {
      margin-top: 13.37px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 20px;
    }
    .news__description {
      color: #fff;
      font-size: 28px;
      margin-top: 12px;
      font-family: $ffSFRegular;
    }
  }
}

@media only screen and (max-width: $xxl) {
  .news {
    @include display-row-between;
    width: 100%;
    margin: 0 auto;
    max-width: 1324px;
    margin-top: 153px;
    .news__img {
      max-width: 652px;
      max-height: 389px;
    }
  }
}

@media only screen and (max-width: $xl) {
  .news {
    @include display-column-center;
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 auto;
    max-width: 752px;
    margin-top: 113px;
    .news__info {
      margin-bottom: 40px;
      margin: 40px 0;
    }
    .news__img {
        margin: 0 auto;
        max-width: 752px;
        max-height: 409px;
    }
  }
}

@media only screen and (max-width: $md) {
    .news {
        display: none;
    }
}