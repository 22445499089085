/* @font-face {
    font-family: "OpenSans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("OpenSans-Regular-400"), url(./openSans.ttf) format("ttf");
  } */
  @font-face {
    font-family: "SFProDisplay-Heavy";
    src: local("SFProDisplay-Heavy"),
      url(./SFProDisplay/SFProDisplay-Heavy.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Black";
    src: local("SFProDisplay-Black"),
      url(./SFProDisplay/SFProDisplay-Black.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Bold";
    src: local("SFProDisplay-Bold"),
      url(./SFProDisplay/SFProDisplay-Bold.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Medium";
    src: local("SFProDisplay-Medium"),
      url(./SFProDisplay/SFProDisplay-Medium.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Regular";
    src: local("SFProDisplay-Regular"),
      url(./SFProDisplay/SFProDisplay-Regular.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Semibold";
    src: local("SFProDisplay-Semibold"),
      url(./SFProDisplay/SFProDisplay-Semibold.woff) format("woff");
  }
  @font-face {
      font-family: "SFProDisplay-Light";
      src: local("SFProDisplay-Light"),
      url(./SFProDisplay/SFProDisplay-Light.woff) format("woff");
  }
  @font-face {
    font-family: "SFProDisplay-Thin";
    src: local("SFProDisplay-Thin"),
      url(./SFProDisplay/SFProDisplay-Thin.woff) format("woff");
  }