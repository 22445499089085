.mobile {
  @include display-row-between;
  align-items: flex-end;
  max-width: 1443px;
  margin: 0 auto;
  margin-top: 76px;
  padding: 0 20px;
  box-sizing: border-box;
  .mobile__block {
    @include display-column-center;
    align-items: center;
    color: #fff;
    max-width: 405px;
    margin-bottom: 20px;
    .mobile__info {
      @include display-column-center;
      align-items: center;
      .mobile__icon {
        width: 130px;
        height: 130px;
      }
      .mobile__title {
        font-size: 60px;
        margin-top: 45px;
        text-align: center;
        font-family: $ffSFLight;
      }
    }
    .mobile__qrs__wrapper {
      @include display-column-between;
      width: 100%;
      margin-top: 33px;
      .mobile__qr__subtitle {
        font-size: 28px;
        text-align: center;
        font-family: $ffSFRegular;
      }
      .mobile__qrs {
        @include display-row-between;
        .mobile__qr {
          @include display-column-center;
          margin-top: 19px;
          .mobile__qr__img {
            margin: 0 auto;
          }
          .mobile__qr__url {
            @include display-column-center;
            margin-top: 33px;
            font-size: 20px;
            color: #18acff;
            text-align: center;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .mobile__img {
    width: 960px;
    height: 484px;
  }
}

@media only screen and (max-width: $xxl) {
  .mobile {
    @include display-row-around;
    margin-top: 46px;
    .mobile__block {
      max-width: 380px;
      .mobile__info {
        .mobile__title {
          font-size: 52px;
          margin-top: 35px;
        }
      }
      .mobile__qrs__wrapper {
        @include display-column-between;
        width: 100%;
        margin-top: 33px;
        .mobile__qr__subtitle {
          font-size: 24px;
        }
        .mobile__qrs {
          @include display-row-between;
          margin-top: 19px;
          .mobile__qr {
            @include display-column-center;
            margin-top: 15px;
            .mobile__qr__url {
              margin-top: 25px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .mobile__img {
      width: 760px;
      height: 384px;
    }
  }
}

@media only screen and (max-width: $xl) {
  .mobile {
    @include display-column-center;
    margin-top: 46px;
    .mobile__block {
      @include display-row-around;
      width: 100%;
      max-width: 920px;
      margin: 0 auto;
      .mobile__info {
        .mobile__icon {
          width: 110px;
          height: 110px;
        }
        .mobile__title {
          font-size: 52px;
          margin-top: 35px;
        }
      }
      .mobile__qrs__wrapper {
        @include display-column-between;
        width: 100%;
        max-width: 340px;
        margin-top: 33px;
        .mobile__qr__subtitle {
          font-size: 24px;
        }
        .mobile__qrs {
          @include display-row-between;
          margin-top: 19px;
          .mobile__qr {
            @include display-column-center;
            margin-top: 15px;
            .mobile__qr__url {
              margin-top: 25px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .mobile__img--wrapper {
      margin: 0 auto;
      .mobile__img {
        margin: 0 auto;
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .mobile {
    margin-top: 65px;
    .mobile__block {
      @include display-column-center;
      max-width: 920px;
      margin: 0 auto;
      .mobile__info {
        .mobile__icon {
          width: 110px;
          height: 110px;
        }
        .mobile__title {
          font-size: 32px;
          margin-top: 35px;
        }
      }
      .mobile__qrs__wrapper {
        @include display-column-between;
        width: 100%;
        max-width: 440px;
        margin-top: 20px;
        .mobile__qr__subtitle {
          font-size: 24px;
        }
        .mobile__qrs {
          @include display-row-between;
          margin-top: 41px;
          .mobile__qr {
            @include display-column-center;
            margin-top: 15px;
            .mobile__qr__url {
              margin-top: 20px;
              font-size: 20px;
            }
          }
        }
      }
    }
    .mobile__img {
      margin: 0 auto;
      margin-top: 40px;
      width: 715px;
      height: 320px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .mobile {
    padding: 0;
    .mobile__block {
      padding: 0 20px;
      .mobile__info {
        max-width: 294px;
        .mobile__icon {
          width: 246px;
          height: 246px;
        }
        .mobile__title {
          font-size: 32px;
          margin-top: 35px;
        }
      }
      .mobile__qrs__wrapper {
        @include display-column-between;
        width: 100%;
        max-width: 294px;
        margin-top: 20px;
        .mobile__qr__subtitle {
          font-size: 24px;
        }
        .mobile__qrs {
          @include display-column;
          margin-top: 0;
          .mobile__qr {
            @include display-column-center;
            margin-top: 41px;
            .mobile__qr__img {
              width: 246px;
              height: 246px;
            }
            .mobile__qr__url {
              margin-top: 20px;
              font-size: 30px;
              font-family: $ffSFMedium;
            }
          }
        }
      }
    }
    .mobile__img--wrapper {
      width: 100vw;
      margin-top: 40px;
      .mobile__img {
        width: 715px;
        height: 360px;
        // overflow-x: auto;
        // overflow-x: scroll;
      }
    }
  }
}
