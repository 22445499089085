.slider {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-top: 64px;
  padding: 20px;
  &__img {
    width: 100%;
    max-width: 100%;
  }
}

.swiper {
  width: 100%;
  max-width: 1320px;
  height: 100%;
  max-height: auto;
  border-radius: 12px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: white;
  color: black;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: $sm) {
  .slider {
    display: none;
  }
}
