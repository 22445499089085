.auth {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  &__container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  &__title {
    font-size: $fs48px;
  }
}