.nav {
  &__list {
    @include display-row;
  }
  &__item {
    color: $light;
    margin-right: 40px;
  }
  &__item:last-of-type {
    margin-right: 0px;
  }
  &__item:hover {
    color: $action;
  }
}
