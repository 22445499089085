.socials {
    @include display-row;
    &__item {
        margin-right: 30px;
    }
    &__item:last-of-type {
        margin-right: 0;
    }
    svg {
        fill: $light;
        width: 38px;
        height: 38px;
    }
}