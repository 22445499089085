@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  overflow-x: hidden;
  max-width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}
input,
button {
  border: none;
  outline: none;
  padding: 0;
}
a {
  all: unset;
  text-decoration: none;
}
a.text:active {
  text-decoration: none;
}

section {
  position: relative;
  z-index: 1;
  font-weight: 700;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #fff;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  //TODO
  //смена фона при прокрутке scroll
  background: #000;
}

.container {
  width: 100%;
  max-width: 1664px;
  margin: 0 auto;
  box-sizing: border-box;

  //TODO
  //@media only screen and (max-width: 1366px) {
  //  width: calc(100vw - 30px);
  //  padding: 0 15px;
  //}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

h2 {
  font-weight: 700;
  text-transform: uppercase;

  //@media only screen and (max-width: 1366px) {
  //}
}

.button-more {
  text-decoration-line: underline;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: red;
  }

  @media only screen and (max-width: 1366px) {
    margin-top: 25px;
  }
}

.loader {
  min-height: 20vh;
  width: 100%;
  position: relative;

  img {
    max-width: 50px;
    animation: beat 0.75s linear infinite;
  }
}

.page {
  padding: 40px 0;

  &-title {
    text-transform: uppercase;
    line-height: 125%;
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
