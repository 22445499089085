.theme__mode--btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 51px;
  height: 26px;
  padding: 5px;
  border-radius: 50px;
  background-color: #272727;
  order: 9;
  &::before {
    position: absolute;
    top: 1px;
    left: 1px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    transition: left 0.2s ease-in;
    content: "";
  }
  &--active::before {
    left: 26px;
  }
  &__icon {
    position: relative;
    z-index: 9;
  }
}