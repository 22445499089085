.robot {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .robot__media {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 612px;
    .creature {
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      background-image: url("../assets/image/webp/pngrb.png");
    }
    .eclipse {
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 110%;
      margin: 0 auto;
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      background-image: url("../assets/image/webp/eclipse.png");
    }
    .media__texts {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: -185px;
      margin-left: 35px;
      display: flex;
      flex-direction: column;
      z-index: 5;
      font-family: "SF Pro Display";
      font-weight: 100;
    }
    .media__title {
      box-sizing: border-box;
      font-size: 176.986px;
      letter-spacing: 88.493px;
      color: #fff;
      text-align: center;
      text-shadow: 0px 3.371px 84.279px rgba(0, 0, 0, 0.25);
      font-weight: 100;
      line-height: normal;
      margin-left: 50px;
      margin-top: -55px;
      font-family: $ffSFThin;
      @media screen and (max-width: $xl) {
        font-size: 126.986px;
        letter-spacing: 48.493px;
      }
    }
    .media__subtitle {
      box-sizing: border-box;
      font-size: 28px;
      color: #fff;
      text-align: center;
      line-height: normal;
      font-family: $ffSFRegular;
      // @media screen and (max-width: $xl) {
      //   font-size: 20px;
      // }
    }
  }
}

@media only screen and (max-width: $lg) {
  .robot {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .media__shadow {
      display: none;
    }
    .robot__media {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 512px;
      .media__texts {
        margin-bottom: -120px;
        margin-left: 35px;
      }
    }
  }
}
@media only screen and (max-width: $md) {
  .robot {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .media__shadow {
      display: none;
    }
    .robot__media {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 512px;
      .media__texts {
        margin-bottom: -110px;
        margin-left: 35px;
        box-sizing: border-box;
      }
      .media__title {
        font-size: 60px;
        letter-spacing: 30px;
        margin-left: 0;
        box-sizing: border-box;
      }
      .media__subtitle {
        font-size: 22px;
        margin-top: 20px;
        box-sizing: border-box;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .robot {
    .robot__media {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 450px;
      margin-top: 100px;
      .media__texts {
        top: 20px;
        margin-bottom: 0;
        margin-left: 0;
        z-index: 8;
        max-width: 333px;
        margin: 0 auto;
      }
      .media__title {
        font-size: 60px;
        letter-spacing: 30px;
        margin-left: 0;
      }
      .media__subtitle {
        font-size: 22px;
        margin-top: 20px;
      }
      .creature {
        background-image: url("../assets/image/webp/rmobpng.png");
      }
    }
  }
}
