@mixin reed {
  outline: 1px red solid;
}
@mixin display-column {
  display: flex;
  flex-direction: column;
}
@mixin display-row {
  display: flex;
  flex-direction: row;
}
@mixin display-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@mixin display-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@mixin display-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@mixin display-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@mixin display-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@mixin flex-justify-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin flex-justify-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
