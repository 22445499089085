// #breakpoints
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// #var-colors
$primary: #010101;
$light: #fff;
$action: #5c62ec;
$grey: #ffffff80;
$dark: #1e1e1e;

// #var-bg-color
$bgprimary: #171718;
$bglight: #fff;

// #var-font-size
$fs48px: 48px;
$fs36px: 36px;
$fs24px: 24px;
$fs16px: 16px;
$fs14px: 14px;
$fs12px: 12px;

// #var-box-shadow
$box-shadow-dark: 0px 5px 35px 0px rgba(0, 0, 0, 0.25);

// #var-fonts
$ffSF: "SF Pro Display";
$ffSFThin: "SFProDisplay-Thin";
$ffSFLight: "SFProDisplay-Light";
$ffSFRegular: "SFProDisplay-Regular";
$ffSFMedium: "SFProDisplay-Medium";
