.notes {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-content: center;
  --background-color: $bglight;
}

.notes.dark {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-content: center;
  --background-color: $bgprimary;
}

.notes__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem;
  grid-gap: 2rem;
  box-sizing: border-box;
  @media only screen and (max-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem;
    grid-gap: 1rem;
  }
  @media only screen and (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
    padding: 1.5rem;
    grid-gap: 1.5rem;
  }
}

.notes__item {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 425px;
  padding: 20px;
  @include display-column;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-shadow: $box-shadow-dark;
  &--img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 20px;
  }
  &--title {
    font-size: 36px;
    z-index: 2;
  }
  &--desc {
    font-size: 16px;
    z-index: 2;
  }
}
