.preview {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  &__content {
    @include display-column;
  }
  &__title {
    max-width: 700px;
    font-size: 36px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3.371px 84.279px rgba(0, 0, 0, 0.25);
    line-height: normal;
    margin: 0 auto;
    font-family: $ffSFThin;
    @media screen and (max-width: $xl) {
    }
  }
  &__subtitle {
    max-width: 500px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    line-height: normal;
    margin-top: 40px;
    font-family: $ffSFThin;
    @media screen and (max-width: $xl) {
      // font-size: 20px;
    }
  }
  &__video__wrapper {
    @include flex-justify-column-center;
    margin: 103px auto;
    margin-top: 285px;
    width: 100%;
    max-width: 1284px;
    height: 100%;
    min-height: 480px;
  }
  &__video {
    width: 100%;
    max-width: 1284px;
    height: 100%;
    min-height: 480px;
    padding: 20px;
    box-sizing: border-box;
  }
  .media__wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: $sm) {
  .preview {
    .media__wrapper {
      flex-direction: column-reverse;
    }
    &__content {
      flex-direction: column-reverse;
    }
    &__video__wrapper {
      @include flex-justify-column-center;
      margin: 0 auto;
      margin-top: 61px;
    }
    &__title {
      max-width: 294px;
      font-size: 32px;
      color: #fff;
      text-align: left;
      text-shadow: 0px 3.371px 84.279px rgba(0, 0, 0, 0.25);
      font-family: $ffSFLight;
      margin-top: -50px;
    }
    &__subtitle {
      max-width: 294px;
      font-size: 20px;
      color: #fff;
      text-align: left;
      margin-top: 20px;
      font-family: $ffSFMedium;
    }
  }
}
